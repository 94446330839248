import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3">
                    Freight Management System
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="box-lmsc">
                      <div className="WShadow1FLMS">
                        <img src={require("./../../../images/flms/freight_18551383.png")} alt=""/>
                        <h4 className="post-title">Ocean freight</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="box-lmsc">
                      <div className="WShadow1FLMS">
                        <img src={require("./../../../images/flms/air-freight_5428412.png")} alt=""/>
                        <h4 className="post-title">Air freight</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="box-lmsc">
                      <div className="WShadow1FLMS">
                        <img src={require("./../../../images/flms/flatbed_18551267.png")} alt=""/>
                        <h4 className="post-title">Road Freight</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 offset-lg-4">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <div className="box-lmsc">
                      <div className="WShadow1FLMS">
                        <img src={require("./../../../images/flms/notes_6690799.png")} alt=""/>
                        <h4 className="post-title">Documentation & Compliance</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title common-enq-bot1 p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blog2;
