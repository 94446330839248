import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import FLMSAbout from "./../Elements/FreightLogisticsManagementSystem/FLMSAbout";
import FLMSBanner from "./../Elements/FreightLogisticsManagementSystem/FLMSBanner";
import FLMSServices from "./../Elements/FreightLogisticsManagementSystem/FLMSServices";
import FLMSMS from "./../Elements/FreightLogisticsManagementSystem/FLMSMS";
import FLMSWhyChooseUs from "./../Elements/FreightLogisticsManagementSystem/FLMSWhyChooseUs";
import FLMSBestServices from "./../Elements/FreightLogisticsManagementSystem/FLMSBestServices";
import FLMSListOption from "./../Elements/FreightLogisticsManagementSystem/FLMSListOption";
import FLMSOverview from "./../Elements/FreightLogisticsManagementSystem/FLMSOverview";
import CommenEnquiry from "./../Elements/CommenEnquiry";

import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
       <Helmet>
          <title>AI is revolutionizing the way we create, manage, and interact with web</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/search-engine-optimization" />
     </Helmet>
     <Header2 />
        <div className="page-content">
         <FLMSBanner/>
         <FLMSAbout/>
         <FLMSBestServices />
         <FLMSMS/>
         <FLMSListOption />
         <CommenEnquiry />
         <FLMSOverview/>
         <FLMSWhyChooseUs/>

       </div>

    <Footer2 />

      </>
    );
  }
}

export default Service1;
