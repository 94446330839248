import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
       <div className="section-full mobile-page-padding p-t80 p-b50 servdert-one ertyu45">
        <div className="container">
        <div className="section-content sfsfcsc13232">
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                        <img src={require('./../../../images/logicis/bag.png')} alt="" />  
                        <h4>Freight Management System</h4>
                    </div>
                    <p>A Freight Management System (FMS) is a software solution that streamlines the planning, execution, and tracking of freight shipments. It optimizes carrier selection, automates billing, provides real-time shipment visibility, and enhances cost efficiency for logistics operations.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/process.png')} alt="" />  
                    <h4>Container Management</h4>
                    </div>
                        <p>Shipment/Container Management is a logistics solution that oversees the tracking, scheduling, and handling of shipments and containers. It ensures efficient cargo movement, real-time visibility, optimized space utilization, and compliance with shipping regulations.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/travel.png')} alt="" />  
                    <h4>Inland Transportation</h4>
                    </div>
                       <p>Inland Transportation refers to the movement of goods via road, rail, or inland waterways between ports, warehouses, and final destinations. It ensures efficient, cost-effective, and timely delivery within a country or region.</p>
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/order-done.png')} alt="" />  
                    <h4>Purchase Order</h4>
                    </div>
                        <p>A Purchase Order (PO) is a formal document issued by a buyer to a seller, specifying the types, quantities, and agreed prices of goods or services. It serves as a legally binding agreement to authorize a transaction.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/seller.png')} alt="" />  
                    <h4>Custom Brokering</h4>
                    </div>
                        <p>Customs Broking is the process of facilitating the clearance of goods through customs authorities by ensuring compliance with regulations, handling documentation, and paying duties and taxes on behalf of importers and exporters.</p>
                    </div>
                </div>
               
                <div className="col-lg-4 col-md-12 col-sm-12">
                    {/* COLUMNS 2 */}
                    <div className="Box-table">
                    <div className="alscmscs">
                    <img src={require('./../../../images/logicis/conversion-rate.png')} alt="" />  
                    <h4>Customer Visibility</h4>
                    </div>
                        <p>Customer Visibility refers to real-time tracking and transparency in supply chain operations, allowing customers to monitor their shipments, orders, and logistics status for improved planning and decision-making.</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    
</div>

      </>
    );
  }
}

export default Blog2;
