import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
              <div className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}>
                <div className="container-fluid">
                    <div className="section-content">
                        <div className="container">
                          <div className="qpqq">
                            <div className="row ffeefefeeeecece">
                              <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="m-b30 bg-white">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/logicis/6106299.jpg')} alt="" />  
                                    </div>
                                </div>
                                </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                               <div className="section-head">
                                  <div className="sx-separator-outer">
                                     <div className="">
                                         <h3 className="ert678">Packaging</h3>
                                         <p>Logistics packaging refers to the specialized packaging used for transporting, storing, 
                                        and handling goods efficiently and safely throughout the supply chain. It plays a crucial role in 
                                        protecting products from damage, 
                                        optimizing storage space, and ensuring smooth distribution from manufacturers to end consumers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid">
                    <div className="section-content">
                        <div className="container">
                          <div className="qpqq">
                            <div className="row ffeefefeeeecece">
                              
                            <div className="col-xl-6 col-lg-12 col-md-12">
                               <div className="section-head">
                                  <div className="sx-separator-outer">
                                     <div className="">
                                         <h3 className="ert678">Delivery Services</h3>
                                         <p>Delivery services refer to the transportation and distribution of goods, packages, or 
                                         documents from one location to another, ensuring timely and secure arrival. These services are essential in various industries, 
                                         including e-commerce, food delivery, healthcare, and retail.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="m-b30 bg-white">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/logicis/18953904_6055612.jpg')} alt="" />  
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid">
                    <div className="section-content">
                        <div className="container">
                          <div className="qpqq">
                            <div className="row ffeefefeeeecece">
                              <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="m-b30 bg-white">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/logicis/5157442.jpg')} alt="" />  
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12">
                               <div className="section-head">
                                  <div className="sx-separator-outer">
                                     <div className="">
                                         <h3 className="ert678">AirMail</h3>
                                         <p>Airmail refers to a postal service that transports letters, parcels, 
                                         and packages via aircraft rather than ground or sea transportation. 
                                         It is typically faster than surface mail and is used for domestic and international shipping when speed is a priority.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid">
                    <div className="section-content">
                        <div className="container">
                          <div className="qpqq">
                            <div className="row ffeefefeeeecece">
                              
                            <div className="col-xl-6 col-lg-12 col-md-12">
                               <div className="section-head">
                                  <div className="sx-separator-outer">
                                     <div className="">
                                         <h3 className="ert678">Delivery</h3>
                                         <p>Delivery refers to the process of transporting goods, products, or services from a sender or retailer to a recipient. 
                                         This can involve various methods and channels depending on the nature of the product, urgency, and distance. Delivery services 
                                         are critical in e-commerce, retail, logistics, and many other industries, ensuring goods arrive 
                                         safely and on time to their intended destinations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                                <div className="m-b30 bg-white">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/logicis/6106299.jpg')} alt="" />  
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
                
            </>
        );
    }
};

export default About3;