import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import videoBg from "./../../../images/logisoft.mp4";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 back-ground-color`}
        >
        <div className="about_image_flms1123">
          <img className="about_image_flms1" src={require("./../../../images/16339534_v910-aew-069.jpg")} alt=""/>
        </div>
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="qpqq">
                  <div className="row">
                   {/* <div className="col-xl-5 col-lg-5 col-md-5">
                    <img className="" src={require("./../../../images/102.jpg")} alt=""/>
                    </div>*/}
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="section-head">
                        <div className="sx-separator-outer">
                          <div className="text-center">
                            <h1 className="ert678">
                              Key Features
                            </h1>
                            <p>LogiSoft solution provides a single-window view of operational excellence across locations facilitating planning, execution, monitoring and control of freight movements. It helps manage revenue flows, streamlines documentation and meets regulatory requirements. LogiSoft tracks job status and enhances the customer satisfaction by providing real-time updates.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
