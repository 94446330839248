import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../../images/main-slider/slider5/new-slider-bg.webp");
var bgimg2 = require("./../../../images/background/cross-line2.png");
var bgimg3 = require("./../../../images/main-slider/slider5/Untitled-6.png");
var bgimg4 = require("./../../../images/update1/f2.webp");
var bgimg5 = require("./../../../images/update1/Banner.webp");

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding dfbb7680978  p-b10 bg-repeat ertyuioiu1 ejbjcnjvf"
          style={{ backgroundImage: "url(" + bgimg3 + ")" }}
        >
          {/* <div className="ertyuioiu-1"></div>
          <div className="ertyuioiu-2"></div>
          <div className="ertyuioiu-3"></div> */}
          <div className="container">
            <div className="row">
              {/* Head Office */}

              <div className="col-md-9">
                <div className="section-one sfwd1324 rrtyui">
                  <div className="banner-content rtyuiopoiu">
                    <h1 className="f-h1">
                      Accelerate Your Business<br/>Technology That Drives Success
                    </h1>
                    <p>
                      Unlocking New Horizons with Next-Gen IT Solutions That Turn Technology Into Your Business’s Greatest Asset for Growth and Innovation
                    </p>

                    <div className="dndu108">
                    <a href="https://www.google.com/partners/agency?id=4328355085" target="_blank" className="google-patner">
                    <img src={require("./../../../images/Layer 0.png")}/>
                    </a>
                      <img
                        src={require("./../../../images/background/Amazon-1-1.png")}
                        className="iuytre"
                        alt="shadow_partner"
                      />
                      <img
                        src={require("./../../../images/update1/meta1.webp")}
                        className="iuytre"
                        alt="shadow_partner"
                      />

                      <img
                        src={require("./../../../images/govermnent of india logo.png")}
                        className="iuytre"
                        alt="shadow_partner"
                      />
                      <img
                        src={require("./../../../images/Layer.png")}
                        className="iuytre1"
                        alt="shadow_partner"
                      />

                    </div>
                    {/* <div className="buttonfgerb">
                      <div className="header-section-12">
                        <NavLink
                          to={"/about-us"}
                          title="READ MORE"
                          rel="bookmark"
                          className="hs12-button"
                        >
                          Find Out More
                        </NavLink>
                      </div>
                    </div> */}
                    <button
                      class="darksoul-hover-fill-button2 mt-3 ml-3"
                      type="button"
                    >
                      <div class="color-fill-2"></div>
                      <NavLink to={"/about-us"} rel="bookmark" className="">
                        Find Out More
                      </NavLink>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
